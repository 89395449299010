import React from "react"
import { createTheme, Typography, useMediaQuery } from "@material-ui/core"
import { useIntl } from "react-intl";
import { withStyles } from "@mui/styles";
import MaintenancePageImage from '../../../assets/img/illustration-maintenance.png'
import { neutralColors } from "../../../themes";

const styles = {
    root: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-evenly',
        width: '100%',
        flexDirection: 'row',
        [createTheme().breakpoints.down('sm')]: {
            flexDirection: 'column-reverse',
            gap: '2rem'
        },
    },
    content: {
        display: 'flex',
        flexDirection: 'column',
        gap: '2.5rem',
        [createTheme().breakpoints.down('sm')]: {
            alignItems: 'center',
        },
    },
    title: {
        textAlign: "center"
    },
    subtitle: {
        textAlign: 'unset',
        [createTheme().breakpoints.down('sm')]: {
            textAlign: "center",
        },
    },
    description: {
        display: 'flex',
        flexDirection: 'column',
        gap: '1rem'
    },
    image: {
        width: "24rem",
        [createTheme().breakpoints.down('sm')]: {
            width: '16rem',
        },
    }
}

const MaintenancePage = ({ ...props }) => {
    const intl = useIntl()
    const isMobile = useMediaQuery(createTheme().breakpoints.down('sm'))

    return (
        <div className={props.classes.root}>
            <div className={props.classes.content}>
                <div className={props.classes.description}>
                    <Typography variant={'h1'} component={'h1'} className={`${isMobile ? 'underline-center' : 'underline-left'} ${props.classes.title}`}>
                        {intl.formatMessage({ id: 'spider.errorPages.maintenancePage.title' })}
                    </Typography>
                    <p className={props.classes.subtitle}>
                        <Typography as="span" variant={'body2'} style={{ color: neutralColors.neutral600 }}>
                            {intl.formatMessage({ id: 'spider.errorPages.maintenancePage.description1' })}
                        </Typography>
                        <Typography as="span" variant={'body2'} style={{ color: neutralColors.neutral600 }}>
                            {intl.formatMessage({ id: 'spider.errorPages.maintenancePage.description2' })}
                        </Typography>
                    </p>
                </div>
            </div>
            <img className={props.classes.image} src={MaintenancePageImage} alt={'404 image'} />
        </div>
    )
}

const Page = withStyles(styles)(MaintenancePage)
export { Page as MaintenancePage }